import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    ArcElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MenuProps } from './MenuProps';
import brLocale from 'date-fns/locale/pt-BR';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Button, FormControl, InputLabel, Select, Stack, TextField } from '@mui/material';
import Typography from "@material-ui/core/Typography";
import api from '../../services/api';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import './button.css';

ChartJS.register(
    ArcElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'left',
            display: true,
        },
        title: {
            display: true,
            text: 'Tickets por usuário',
            position: 'top',
        },
        datalabels: {
            display: true,
            anchor: 'center',
            align: 'center',
            color: "#fff",
            font: {
                size: 16,
                weight: "bold"
            },
        },
        cutout: '80%',
    },
};

export const ChatsUserClosed = ({ initialDate, finalDate }) => {

    //const [initialDate, setInitialDate] = useState(new Date());
    //const [finalDate, setFinalDate] = useState(new Date());
    const [ticketsData, setTicketsData] = useState({
        data: [],
        count: 0,
    });

    useEffect(() => {
        handleGetTicketsInformation();
    }, [initialDate, finalDate]);

    //função para criar cores aleatórias de acordo com a quantidade de usuários
    const randomColor = () => {
        let letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    const dataCharts = {

        labels: ticketsData && ticketsData?.data.length > 0 && ticketsData?.data.map((item) => item.nome),
        datasets: [
            {
                label: 'Tickets',
                data: ticketsData?.data.length > 0 && ticketsData?.data.map((item, index) => {
                    return item.quantidade
                    // return item.npsGeneral
                }),
                borderRadius: 10,
                backgroundColor: [
                    '#FF6384',
                    '#36A2EB',
                    '#FFCE56',
                    '#4BC0C0',
                    '#9966FF',
                    '#FF9F40',                    
                ] || randomColor(),
            },

        ],
    };

    const handleGetTicketsInformation = async () => {
        try {

            const { data } = await api.get(`/dashboard/ticketsUsersClosed?initialDate=${format(initialDate, 'yyyy-MM-dd')}&finalDate=${format(finalDate, 'yyyy-MM-dd')}`);
            setTicketsData(data);
        } catch (error) {
            toast.error('Erro ao buscar informações dos tickets');
        }
    }

    return (
        <>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                Tickets Resolvidos por usuário
            </Typography>
            {/*
      <Stack direction={'row'} spacing={2} alignItems={'center'} sx={{ my: 2, }} >

        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale}>
          <DatePicker
            value={initialDate}
            onChange={(newValue) => { setInitialDate(newValue) }}
            label="Data inicial"
            renderInput={(params) => <TextField fullWidth {...params} sx={{ width: '20ch' }} />}

          />
        </LocalizationProvider>

        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale}>
          <DatePicker
            value={finalDate}
            onChange={(newValue) => { setFinalDate(newValue) }}
            label="Data final"
            renderInput={(params) => <TextField fullWidth {...params} sx={{ width: '20ch' }} />}
          />
        </LocalizationProvider>

        <Button className="buttonHover" onClick={handleGetTicketsInformation} variant='contained'>Filtrar</Button>

      </Stack>
      */}
            <Doughnut options={options} data={dataCharts} style={{ maxWidth: '100%', maxHeight: '280px', }} />
        </>
    );
}