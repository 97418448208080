import React, { useContext, useState } from "react"

import Paper from "@material-ui/core/Paper"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography";
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import useTickets from "../../hooks/useTickets"

import { AuthContext } from "../../context/Auth/AuthContext";
import { ChartsDate } from "./ChartsDate"
import { ChatsUser } from "./ChartsUser"
import { ChartMediumTimeAttendance } from "./ChartMediumTimeAttendance"
import { ChartsNPS } from "./ChartsNPS"

import { i18n } from "../../translate/i18n";

import Chart from "./Chart"
import { ReactComponent as WhatsApp } from 'bootstrap-icons/icons/whatsapp.svg';
import { ReactComponent as HourGlass } from 'bootstrap-icons/icons/hourglass.svg';
import { ReactComponent as CheckCircle } from 'bootstrap-icons/icons/check2-circle.svg';
import { ReactComponent as UserOnline } from 'bootstrap-icons/icons/person-workspace.svg';
import { ReactComponent as ChevronDown } from 'bootstrap-icons/icons/chevron-down.svg';
import { ReactComponent as ChevronUp } from 'bootstrap-icons/icons/chevron-up.svg';
import { ReactComponent as PictureUser } from 'bootstrap-icons/icons/person-circle.svg';
import useUsers from "../../hooks/useUsers"
import { Button, Collapse } from "@material-ui/core"
import Tags from "../Tags"
import TagsReport from "../TagsReport"
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import brLocale from "date-fns/locale/pt-BR";
import { subDays, addDays } from "date-fns";
import { toast } from "react-toastify"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Title from "../../components/Title";
import MainHeader from "../../components/MainHeader"
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper"
import { getBackendUrl } from "../../config"
import { format } from "date-fns"
import api from '../../services/api';
import CardsUsers from "./CardsUsers"
import { ChatsUserClosed } from "./ChartsUserClosed"


const useStyles = makeStyles(theme => ({
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	fixedHeightPaper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: 240,
	},
	fixedHeightPaper2: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "none",
		flexDirection: "column",
		//height: 240,
	},
	customFixedHeightPaper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: 120,
		borderLeft: "8px solid",
	},
	cardUserPaper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: 150,
		borderBottom: "8px solid",
		borderBottomColor: "#7436a3",
	},
	customFixedHeightPaperLg: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: "100%",
	},
	borderColor: {
		flex: "none",
		width: "8px",
		height: "100%",
		position: "absolute",
		top: "0%",
		left: "0%",
		color: "#224abe",
	},
	icon: {
		margin: theme.spacing(1),
		width: "50px",
		height: "50px",
	},
}))

const Dashboard = () => {
	const classes = useStyles()
	const [openTagsReport, setOpenTagsReport] = useState(false);
	const [openCharts, setOpenCharts] = useState(false);
	const [initialDate, setInitialDate] = useState(subDays(new Date(), 30));
	const [finalDate, setFinalDate] = useState(new Date());
	const [initDate, setInitDate] = useState(new Date());
	const [fimDate, setFimDate] = useState(addDays(new Date(), 1));

	const { user } = useContext(AuthContext);
	var userQueueIds = [];

	if (user.queues && user.queues.length > 0) {
		userQueueIds = user.queues.map(q => q.id);
	}

	const GetTickets = (status, showAll, withUnreadMessages) => {

		const { count } = useTickets({
			status: status,
			showAll: showAll,
			withUnreadMessages: withUnreadMessages,
			queueIds: JSON.stringify(userQueueIds)
		});
		return count;
	}

	const GetUsers = () => {
		const { users, count } = useUsers();
		let onlineUsers = 0;
		users.forEach(user => {
			if (user.online === true) {
				onlineUsers = onlineUsers + 1;
			}
		});
		const usersOnline = onlineUsers === 0 ? 0 : onlineUsers;

		return { usersOnline, count, users };
	}

	const GetUsers2 = () => {
		const { users } = useUsers();

		return users;
	}

	const handleGetTicketsInformation = () => {
		toast.success("Filtro de datas aplicado com sucesso!")
	}

	//o finalDate deve ser a data atual

	const filter = subDays(new Date(), 1)
	const formattedDate = format(new Date(), 'dd/MM/yyyy');


	let url = getBackendUrl();
	let totalUsers = GetUsers2();
	


	return (
		<div>
			<Container maxWidth="lg" className={classes.container}>
				<MainHeader>
					<Title>Dashboard</Title>
				</MainHeader>
				<Grid container spacing={3}>
					{/* EM ATENDIMENTO */}
					<Grid item xs={12} sm={3}>
						<Paper className={classes.customFixedHeightPaper} elevation={3} style={{ overflow: "hidden", borderLeftColor: "#51c44d" }}>
							<Grid container direction="row">
								<Grid item xs={9}>
									<Typography component="h3" variant="h6" color="primary" paragraph>
										{i18n.t("dashboard.messages.inAttendance.title")}
									</Typography>
									<Grid>
										<Typography component="h1" variant="h4">
											{GetTickets("open", "true", "false")}
										</Typography>
									</Grid>
								</Grid>
								<Grid item alignContent="center" justifyContent="center" xs={3} >
									<WhatsApp className={classes.icon} style={{ color: "#51c44d" }} />
								</Grid>
							</Grid>
						</Paper>
					</Grid>

					{/* ESPERANDO */}
					<Grid item xs={12} sm={3}>
						<Paper className={classes.customFixedHeightPaper} elevation={3} style={{ overflow: "hidden", borderLeftColor: "#c9c306" }}>
							<Grid container direction="row">
								<Grid item xs={8}>
									<Typography component="h3" variant="h6" color="primary" paragraph>
										{i18n.t("dashboard.messages.waiting.title")}
									</Typography>
									<Grid>
										<Typography component="h1" variant="h4">
											{GetTickets("pending", "true", "false")}
										</Typography>
									</Grid>
								</Grid>
								<Grid item alignContent="center" justifyContent="center" xs={4}>
									<HourGlass className={classes.icon} style={{ color: "#c9c306" }} />
								</Grid>
							</Grid>
						</Paper>
					</Grid>

					{/* ENCERRADOS */}
					<Grid item xs={12} sm={3}>
						<Paper className={classes.customFixedHeightPaper} elevation={3} style={{ overflow: "hidden", borderLeftColor: "#224abe" }}>
							<Grid container direction="row">
								<Grid item xs={8}>
									<Typography component="h3" variant="h6" color="primary" paragraph>
										{i18n.t("dashboard.messages.closed.title")}
									</Typography>
									<Grid>
										<Typography component="h1" variant="h4">
											{GetTickets("closed", "true", "false")}
										</Typography>
									</Grid>
								</Grid>
								<Grid item alignContent="center" justifyContent="center" xs={4}>
									<CheckCircle className={classes.icon} style={{ color: "#224abe" }} />
								</Grid>
							</Grid>
						</Paper>
					</Grid>


					{/* USUÁRIOS ONLINE */}
					<Grid item xs={12} sm={3}>
						<Paper className={classes.customFixedHeightPaper} elevation={3} style={{ overflow: "hidden", borderLeftColor: "#f58138" }}>
							<Grid container direction="row">
								<Grid item xs={8}>
									<Typography component="h3" variant="h6" color="primary" paragraph>
										Usuários online
									</Typography>
									<Grid>
										<Typography component="h1" variant="h4">
											{GetUsers().usersOnline}/{GetUsers().users.length}
										</Typography>
									</Grid>
								</Grid>
								<Grid item alignContent="center" justifyContent="center" xs={4}>
									<UserOnline className={classes.icon} style={{ color: "#f58138" }} />
								</Grid>
							</Grid>
						</Paper>
					</Grid>

					<Grid item xs={12}>
						<MainHeader>
							<Title >Tickets por Usuários</Title>
							<MainHeaderButtonsWrapper>
								<Stack
									direction={{ xs: "column", sm: "row" }}
									spacing={2}
									alignItems={'center'}
									sx={{ my: 2, }}
								>

									<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale}>
										<DatePicker
											value={initDate}
											onChange={(newValue) => { setInitDate(newValue) }}
											label="Data inicial"
											renderInput={(params) => <TextField fullWidth {...params} sx={{ width: '20ch' }} />}

										/>
									</LocalizationProvider>

									<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale}>
										<DatePicker
											value={fimDate}
											onChange={(newValue) => { setFimDate(newValue) }}
											label="Data final"
											renderInput={(params) => <TextField fullWidth {...params} sx={{ width: '20ch' }} />}
										/>
									</LocalizationProvider>

								</Stack>
							</MainHeaderButtonsWrapper>
						</MainHeader>
						<CardsUsers
							initialDate={initDate}
							finalDate={fimDate}
						/>

					</Grid>



					{/*
					{totalUsers.map((users) => (
						<Grid item xs={12} sm={3} key={users.id}>
							<Paper className={classes.cardUserPaper} elevation={3} style={{ overflow: "hidden" }}>
								<Grid container xs={12}>
									{ FOTO E NOME DO USUÁRIO }
									<Grid container item direction="row" xs={12} >
										<Grid item xs={3} >
											{users.mediaUrl && users.mediaUrl !== "" ? (
												<img src={`${url}/public/${users.mediaUrl}`} alt={users.name} style={{ width: 45, height: 45, borderRadius: 100 }} />) : (
												<PictureUser style={{ width: 45, height: 45, borderRadius: 100, color: "#224abe" }} />
											)}
										</Grid>
										<Grid item xs={9} alignItems="center" style={{ verticalAlign: "middle" }}>
											<Typography component="h3" style={{ fontWeight: 700, color: "black" }}>
												{users.name}
											</Typography>
										</Grid>
									</Grid>

									{TICKETS SENDO ATENDIDOS POR USUÁRIO }
									<Grid container item direction="row" xs={12}>
										<Grid item alignContent="center" justifyContent="center" xs={9}>
											<Typography component="body1" color="black" paragraph>
												Tickets em atendendo
											</Typography>
										</Grid>
										<Grid item xs={3}>
											<Typography component="body1" color="black" paragraph>
												{ticketsOpen.filter(ticket => ticket.userId === users.id).length}
											</Typography>
										</Grid>
									</Grid>

									{ TICKETS RESOLVIDOS POR USUÁRIO }
									<Grid container item direction="row" xs={12}>
										<Grid item alignContent="center" justifyContent="center" xs={9}>
											<Typography component="body1" color="black" paragraph>
												Tickets resolvidos
											</Typography>
										</Grid>
										<Grid item xs={3}>
											<Typography component="body1" color="black" paragraph>
												{ticketsClosed.filter(ticket => ticket.userId === users.id).length}
											</Typography>
										</Grid>
									</Grid>
								</Grid>
							</Paper>
						</Grid>
					))}
					*/}


					{/* BOTÕES */}
					<Grid item xs={12}
						style={{
							justifyContent: "space-between",
							display: "flex",
							flexDirection: "row",
						}}>
						<Button
							variant="contained"
							color="primary"
							//href="/tags-reports" 
							onClick={() => setOpenTagsReport(!openTagsReport)}
							style={{ flex: 1, margin: 3 }}
						>
							Relatório de Tags
							{openTagsReport
								? <ChevronUp style={{ marginLeft: "5px" }} />
								: <ChevronDown style={{ marginLeft: "5px" }} />}
						</Button>

						<Button
							variant="contained"
							color="primary"
							//href="/tags-reports" 
							onClick={() => setOpenCharts(!openCharts)}
							style={{ flex: 1, margin: 3 }}
						>
							Gráficos
							{openCharts
								? <ChevronUp style={{ marginLeft: "5px" }} />
								: <ChevronDown style={{ marginLeft: "5px" }} />}
						</Button>
					</Grid>

					<Grid item xs={12}>
						<Collapse in={openTagsReport}>
							<Paper variant="outlined" className={classes.fixedHeightPaper2}>
								<TagsReport />
							</Paper>
						</Collapse>
					</Grid>


					{/* GRÁFICOS */}
					<Grid item xs={12}>
						<Collapse in={openCharts}>
							<Paper variant="outlined" className={classes.fixedHeightPaper2}>
								<MainHeader>
									<Title >Gráficos</Title>
									<MainHeaderButtonsWrapper>
										<Stack
											direction={{ xs: "column", sm: "row" }}
											spacing={2}
											alignItems={'center'}
											sx={{ my: 2, }}
										>

											<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale}>
												<DatePicker
													value={initialDate}
													onChange={(newValue) => { setInitialDate(newValue) }}
													label="Data inicial"
													renderInput={(params) => <TextField fullWidth {...params} sx={{ width: '20ch' }} />}

												/>
											</LocalizationProvider>

											<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale}>
												<DatePicker
													value={finalDate}
													onChange={(newValue) => { setFinalDate(newValue) }}
													label="Data final"
													renderInput={(params) => <TextField fullWidth {...params} sx={{ width: '20ch' }} />}
												/>
											</LocalizationProvider>

										</Stack>
									</MainHeaderButtonsWrapper>
								</MainHeader>
								<Paper elevation={0} style={{ padding: 4 }}>
									<Grid container spacing={1} xs={12}>
										<Grid item xs={12}>
											<ChartsDate
												initialDate={initialDate}
												finalDate={finalDate}
											/>
										</Grid>
										<Grid item xs={12} style={{ marginTop: "15px" }}>
											<ChatsUser
												initialDate={initialDate}
												finalDate={finalDate}
											/>
										</Grid>
										<Grid item xs={12} style={{ marginTop: "15px" }}>
											<ChatsUserClosed
												initialDate={initialDate}
												finalDate={finalDate}
											/>
										</Grid>
										<Grid item xs={12} style={{ marginTop: "15px" }}>
											<ChartMediumTimeAttendance
												initialDate={initialDate}
												finalDate={finalDate}
											/>
										</Grid>
									</Grid>
								</Paper>
							</Paper>
						</Collapse>
					</Grid>

					{/*
					<Grid item container spacing={3}>
						<Grid item xs={6}>
							<Paper className={classes.fixedHeightPaper2}>
								<ChartsDate />
							</Paper>
						</Grid>

						<Grid item xs={6}>
							<Paper className={classes.fixedHeightPaper2}>
								<ChatsUser />
							</Paper>
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<Paper className={classes.fixedHeightPaper2}>
							<ChartMediumTimeAttendance />
						</Paper>
					</Grid>

					<Grid item xs={12}>
						<Paper className={classes.fixedHeightPaper2}>
							<ChartsNPS />
						</Paper>
					</Grid>
					*/}

				</Grid>
			</Container>
		</div >
	)
}

export default Dashboard