import React, { useState, useEffect } from "react";
import openSocket from "../../services/socket-io.js";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { toast } from "react-toastify";

import Tooltip from "@material-ui/core/Tooltip";

import api from "../../services/api.js";
import { i18n } from "../../translate/i18n.js";
import toastError from "../../errors/toastError.js";
import { Grid } from "@material-ui/core";
import MainContainer from "../../components/MainContainer/index.js";
import MainHeader from "../../components/MainHeader/index.js";
import Title from "../../components/Title/index.js";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		alignItems: "center",
		padding: theme.spacing(8, 8, 3),
	},

	paper: {
		padding: theme.spacing(2),
		display: "flex",
		alignItems: "center",
		marginBottom: 12,

	},

	settingOption: {
		marginLeft: "auto",
	},
	margin: {
		margin: theme.spacing(1),
	},
	mainPaper: {
		flex: 1,
		padding: theme.spacing(1),
		overflowY: "scroll",
		...theme.scrollbarStyles,
	  },

}));

const ControlPanel = () => {
	const classes = useStyles();

	const [settings, setSettings] = useState([]);

	useEffect(() => {
		const fetchSession = async () => {
			try {
				const { data } = await api.get("/settings");
				setSettings(data);
			} catch (err) {
				toastError(err);
			}
		};
		fetchSession();
	}, []);

	useEffect(() => {
		const socket = openSocket();

		socket.on("settings", data => {
			if (data.action === "update") {
				setSettings(prevState => {
					const aux = [...prevState];
					const settingIndex = aux.findIndex(s => s.key === data.setting.key);
					aux[settingIndex].value = data.setting.value;
					return aux;
				});
			}
		});

		return () => {
			socket.disconnect();
		};
	}, []);

	const handleChangeSetting = async e => {
		const selectedValue = e.target.value;
		const settingKey = e.target.name;

		try {
			await api.put(`/settings/${settingKey}`, {
				value: selectedValue,
			});
			toast.success(i18n.t("settings.success"));
		} catch (err) {
			toastError(err);
		}
	};

	const getSettingValue = key => {
		const { value } = settings.find(s => s.key === key);
		return value;
	};

	return (

		<MainContainer className={classes.container} >
			<MainHeader>
				<Title>Painel de Controle</Title>
			</MainHeader>
			<Paper className={classes.mainPaper} variant="outlined">
				<Paper className={classes.paper}>
					<Typography variant="body1">
						{i18n.t("settings.settings.userCreation.name")}
					</Typography>
					<Select
						margin="dense"
						variant="outlined"
						native
						id="userCreation-setting"
						name="userCreation"
						value={
							settings && settings.length > 0 && getSettingValue("userCreation")
						}
						className={classes.settingOption}
						onChange={handleChangeSetting}
					>
						<option value="enabled">
							{i18n.t("settings.settings.userCreation.options.enabled")}
						</option>
						<option value="disabled">
							{i18n.t("settings.settings.userCreation.options.disabled")}
						</option>
					</Select>
				</Paper>				

				<Paper className={classes.paper}>
					<TextField
						id="api-token-setting"
						readonly
						label="Token Api"
						margin="dense"
						variant="outlined"
						fullWidth
						value={settings && settings.length > 0 && getSettingValue("userApiToken")}
					/>
				</Paper>

				<Paper className={classes.paper}>
					<Typography variant="body1">
						Tipo do Chatbot
					</Typography>
					<Select
						margin="dense"
						variant="outlined"
						native
						id="chatBotType-setting"
						name="chatBotType"
						value={settings && settings.length > 0 && getSettingValue("chatBotType")}
						className={classes.settingOption}
						onChange={handleChangeSetting}
					>
						<option value="text">
							Texto
						</option>

						<option value="button">
							Botão
						</option>

						<option value="list">
							Lista
						</option>

					</Select>
				</Paper>
				<Paper className={classes.paper}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							<TextField
								id="user-quantity-setting"
								readonly
								label="Quantidade de usuários"
								name="userQuantity"
								margin="dense"
								variant="outlined"
								fullWidth
								value={settings && settings.length > 0 && getSettingValue("userQuantity")}
								onChange={handleChangeSetting}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								id="conection-quantity-setting"
								readonly
								label="Quantidade de conexões"
								name="conectionQuantity"
								margin="dense"
								variant="outlined"
								fullWidth
								value={settings && settings.length > 0 && getSettingValue("conectionQuantity")}
								onChange={handleChangeSetting}
							/>

						</Grid>

					</Grid>

				</Paper>
			</Paper>

		</MainContainer>

	);
};

export default ControlPanel;