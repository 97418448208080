import { Chip, Paper, TextField, Box } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    box: {
        padding: '5px', 
        height: '66px', 
        alignItems: 'center', 
        display: 'flex', 
        justifyContent: 'center', 
        flex: 1,
        [theme.breakpoints.down('sm')]: {
            widht: '100%',
        },
    },
}));

export function TagsFilter({ onFiltered }) {
    const classes = useStyles();

    const [tags, setTags] = useState([]);
    const [selecteds, setSelecteds] = useState([]);

    useEffect(() => {
        async function fetchData() {
            await loadTags();
        }
        fetchData();
    }, []);

    const loadTags = async () => {
        try {
            const { data } = await api.get(`/tags/list`);
            setTags(data);
        } catch (err) {
            toastError(err);
        }
    }

    const onChange = async (value) => {
        setSelecteds(value);
        onFiltered(value);
    }

    return (
        <Box className={classes.box}>
            <Autocomplete
                style={{ width: '100%' }}
                multiple
                size="small"
                options={tags}
                value={selecteds}
                onChange={(e, v, r) => onChange(v)}
                getOptionLabel={(option) => option.name}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            variant="outlined"
                            style={{
                                backgroundColor: option.color || "#eee",
                                textShadow: "1px 1px 1px #000",
                                color: "white",
                            }}
                            label={option.name}
                            {...getTagProps({ index })}
                            size="small"
                        />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Filtro por Tags"
                        sx={{ width: '20ch', height: '100%'}}
                    />
                )}
            />
        </Box>
    )
}