import React from "react";
import Typography from "@material-ui/core/Typography";

export default function Title(props) {
	return (
		<Typography  
		variant="h5"
		color="primary" 
		style={{ textTransform: "uppercase" }} 
		gutterBottom
		sx={{ fontSize:
			{xs: 12, sm: 14}
		 }}
		>
			{props.children}
		</Typography>
	);
}
